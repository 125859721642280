<template>
  <div v-if="!loading" class="user-login">
    <v-container>
      <v-row>
        <v-col lg="12" class="mx-auto">
          <v-card
            elevation="5"
            width="23rem"
            class="user-login-card"
            style="margin: 0 auto"
          >
            <v-card-text class="pt-4">
              <div class="saajha-signup text-center">
                <div class="saajha-logo text-center">
                  <img src="/img/sajha.jpg" alt="" width="120px" />
                </div>
                <div class="signup-title pt-4">
                  <h2 class="font-bold">Vendor Login</h2>
                </div>
                <div class="signup-subtitle">
                  WELCOME! to Saajha Seller Zone
                </div>
                <div class="pt-8 pb-4">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      outlined
                      dense
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="passwordRules"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-2"
                      label="Password"
                      hint="At least 8 characters with one Uppercase, Number and Special Character"
                      class="input-group--focused"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                    <v-btn
                      color="#d2302c"
                      dark
                      :disabled="loginBtn"
                      :loading="loginLoad"
                      @click="login"
                      >Login Securely</v-btn
                    >
                    <p style="font-size: 0.7rem" class="py-2">
                      By continuing, you agree to Saajha's
                      <router-link link to="/"
                        >Terms &amp; Condition</router-link
                      >
                      and consent to our
                      <router-link link to="/">Seller Policy</router-link>
                      and
                      <router-link link to="/">Privacy Policy</router-link>
                    </p>
                    <p
                      class="signin-toogle font-bold"
                      style="font-size: 0.8rem"
                    >
                      New to Here?
                      <router-link to="/seller/register">Register</router-link>
                    </p>
                  </v-form>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <circular-loaders v-else></circular-loaders>
</template>

<script>
import Swal from "sweetalert2";
import CircularLoaders from "../../components/loaders/CircularLoaders.vue";
export default {
  components: { CircularLoaders },
  data() {
    return {
      loading: false,
      loginLoad: false,
      valid: true,
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      show1: false,

      password: "",
      passwordRules: [
        v => !!v || "Password is required",
        v => (v && v.length >= 8) || "Password must have 8+ characters",
        v =>
          /(?=.*[A-Z])/.test(v) || "Password must have one uppercase character",
        v => /(?=.*\d)/.test(v) || "Password must have one number",
        v =>
          /([!@$%])/.test(v) ||
          "Password must have one special character [!@#$%]"
      ]
    };
  },
  computed: {
    loginBtn() {
      return !this.valid;
    }
  },
  methods: {
    login() {
      this.loginLoad = !this.loginLoad;
      const credential = {
        email: this.email,
        password: this.password
      };
      this.$store
        .dispatch("login", credential)
        .then(response => {
          if (response[0] === 422) {
            Swal.fire("Failed", response.message, "error");
          } else {
            if (response.roles.slug === "vendor") {
              this.$router.push("/dashboard");
            } else {
              Swal.fire("Failed", "Unauthorized Access", "error");
            }
          }

          this.loginLoad = !this.loginLoad;
        })
        .catch(error => {
          this.loginLoad = !this.loginLoad;
          Swal.fire("Failed", error.message, "error");
        });
    }
  },
  created() {
    this.loading = true;
    if (localStorage.getItem("v_token")) {
      this.$store
        .dispatch("getUser")
        .then(response => {
          if (response.data.roles.slug === "vendor") {
            const redirectUrl = this.$route.query.redirect;
            redirectUrl
              ? this.$router.push(redirectUrl)
              : this.$router.push("/dashboard");
          } else {
            this.loading = false;
            localStorage.removeItem("v_token");
          }
        })
        .catch(() => {
          localStorage.removeItem("v_token");
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
.user-login {
  background: #f2f2f2;
  padding: 1rem 0;
}
.user-login-card {
  border-radius: 0.75rem !important;
  height: 32rem;
}
</style>
